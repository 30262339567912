var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.countList)?_c('div',{staticClass:"candidate-exams-stats"},[_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(_vm._s(_vm.countList.total))])]}}],null,false,3860646923)},[_c('span',[_vm._v("Students")])])],1),_c('span',[_vm._v("/")]),_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(_vm._s(_vm.countList.total_present))])]}}],null,false,1108956927)},[_c('span',[_vm._v("Presentie")])])],1),_c('span',[_vm._v("/")]),_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(" "+_vm._s(_vm.countList.total_passed))])]}}],null,false,3549738799)},[_c('span',[_vm._v("Passed")])])],1),_c('span',[_vm._v("/")]),_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(_vm._s(_vm.countList.total_failed))])]}}],null,false,1260301207)},[_c('span',[_vm._v("Failed")])])],1),_c('span',[_vm._v("/")]),_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(_vm._s(_vm.countList.total_not_fetched))])]}}],null,false,185961347)},[_c('span',[_vm._v("Not fetched")])])],1),_c('span',[_vm._v("/")]),_c('div',{staticClass:"candidate-exams-stats__item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"candidate-exams-stats__count"},on),[_vm._v(_vm._s(_vm.countList.total_not_came))])]}}],null,false,3785687316)},[_c('span',[_vm._v("Afwezig")])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }