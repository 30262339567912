<template lang="pug">
  .candidate-exams-stats(v-if="countList")
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on") {{ countList.total }}
        span Students
    span /
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on") {{ countList.total_present }}
        span Presentie
    span /
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on")  {{ countList.total_passed }}
        span Passed
    span /
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on") {{ countList.total_failed }}
        span Failed
    span /
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on") {{ countList.total_not_fetched }}
        span Not fetched
    span /
    .candidate-exams-stats__item
      v-tooltip(bottom)
        template(v-slot:activator="{on}")
          span.candidate-exams-stats__count(v-on="on") {{ countList.total_not_came }}
        span Afwezig
</template>

<script>
export default {
  props: {
    countList: {type: Object, required: true}
  }
}
</script>

<style lang="scss" scoped>
.candidate-exams-stats {
  display: flex;
  align-items: center;

  &__item {
    font-size: 14px;
    display: inline-block;
    align-items: center;
    color: #262c33;

    @include media("<=phone") {
      font-size: 12px;
    }
  }

  &__count {
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
